//import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>Joel Yancey · j.y@ucla.edu</p>
        <p>
        <a className="App-link"
          href="https://www.linkedin.com/in/joelyancey/"
          target="_blank"
          rel="noopener noreferrer">LinkedIn</a>
          &nbsp;·&nbsp;<a className="App-link"
          href="https://github.com/joelyancey"
          target="_blank"
          rel="noopener noreferrer">GitHub</a>
          &nbsp;·&nbsp;<a className="App-link"
          href="https://www.youtube.com/@JoelYancey"
          target="_blank"
          rel="noopener noreferrer">YouTube</a>
        </p>
      </header>

      <body>
        <p>Motown is alive! 2022-2023</p>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/videoseries?si=tVMhI8Hdsz9Yf-y3&amp;list=PLmDQKF70E5Lw_LpuaElH_QZXkvOpAevVG" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <p>April 24, 2016 - UCLA Radio Interview</p>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/gXSrLRTRMA4?si=JIDc2gr1QkJDhAAD" title="Joel Yancey UCLA Radio" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </body>
    </div>
  );
}

export default App;
